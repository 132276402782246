/**
 * CSS transition-based accordion
 */

export default ((document) => {
    const accordions = Array.from(document.querySelectorAll('.js-accordion'));
    let timer = false;

    function openPanel(panel) {
        // Calculate the full-expanded height
        const height = panel.scrollHeight;

        requestAnimationFrame(() => {
            // Start the expand transition
            panel.style.height = `${height}px`;
        });

        timer = setTimeout(() => {
            // Set the height back to auto, so window resizing works properly
            panel.style.height = 'auto';
        }, 500);
    }

    function closePanel(panel) {
        if (timer) {
            // Make sure we don't reset to height: auto
            clearTimeout(timer);
        }

        // Temporarily disable all css transitions
        var transition = panel.style.transition;
        panel.style.transition = '';

        requestAnimationFrame(() => {
            // Set the current height as the max-height, so we
            // have a starting point for our transition
            panel.style.height = `${panel.scrollHeight}px`;
            panel.style.transition = null;

            requestAnimationFrame(() => {
                // Contract the panel
                panel.style.height = 0;
            });
        })
    }

    accordions.forEach((wrapper, accordionIndex) => {
        const headings = Array.from(wrapper.querySelectorAll('.js-accordion-heading'));
        const panels = Array.from(wrapper.querySelectorAll('.js-accordion-panel'));

        // Set attributes on the wrapper element
        wrapper.setAttribute('role', 'tablist');
        wrapper.setAttribute('aria-multiselectable', 'true');

        panels.forEach((panel, panelIndex) => {
            const connectedHeading = panel.previousElementSibling;
            const closeButton = panel.querySelector('.close');

            if (closeButton) {
                closeButton.addEventListener('click', () => {
                    const event = document.createEvent('HTMLEvents');
                    event.initEvent('click', true, false);
                    connectedHeading.querySelector('button').dispatchEvent(event);
                });
            }

            panel.setAttribute('id', `accordion${accordionIndex}-panel${panelIndex}`);
            panel.setAttribute('aria-labeledby', `accordion${accordionIndex}-heading${panelIndex}`);
            panel.setAttribute('aria-hidden', 'true');
            panel.setAttribute('role', 'tabpanel');
        });

        headings.forEach((heading, headingIndex) => {
            const button = heading.querySelector('button');
            const connectedPanel = heading.nextElementSibling;

            heading.setAttribute('id', `accordion${accordionIndex}-tab${headingIndex}`);
            heading.setAttribute('aria-controls', `accordion${accordionIndex}-panel${headingIndex}`);
            heading.setAttribute('aria-expanded', 'false');
            heading.setAttribute('role', 'tab');

            button.addEventListener('click', () => {
                if (heading.getAttribute('aria-expanded') === 'true') {
                    connectedPanel.setAttribute('aria-hidden', 'true');
                    heading.setAttribute('aria-expanded', 'false');
                    closePanel(connectedPanel);
                } else {
                    // Close other panels
                    headings.forEach((el) => {
                        if (el !== heading && el.getAttribute('aria-expanded') === 'true') {
                            const elConnectedPanel = el.nextElementSibling;
                            elConnectedPanel.setAttribute('aria-hidden', 'true');
                            el.setAttribute('aria-expanded', 'false');
                            closePanel(elConnectedPanel);
                        }
                    });

                    // Open this one
                    connectedPanel.setAttribute('aria-hidden', 'false');
                    heading.setAttribute('aria-expanded', 'true');
                    openPanel(connectedPanel);
                }
            });
        });
    });
})(window.document);

/**
 * Close one toggle when another opens
 */

export default ((document) => {
    const toggles = Array.prototype.slice.call(
        document.querySelectorAll('.connected-toggles [data-a11y-toggle]'),
    );

    function collapse(toggle) {
        const id = toggle.getAttribute('data-a11y-toggle');
        const collapsibleBox = document.getElementById(id);
        collapsibleBox.setAttribute('aria-hidden', true);
        toggle.setAttribute('aria-expanded', false);
    }

    function collapseAll(event) {
        const panelId = event.currentTarget.getAttribute('data-a11y-toggle');
        toggles.filter(toggle => toggle.getAttribute('data-a11y-toggle') !== panelId).forEach(collapse);
    }

    toggles.forEach((toggle) => {
        toggle.addEventListener('click', collapseAll);
    });
})(window.document);

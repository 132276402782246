/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['ev-emitter/ev-emitter', 'fizzy-ui-utils/utils', './flickity'], function(EvEmitter, utils, Flickity) {
      return factory(EvEmitter, utils, Flickity);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(require('ev-emitter'), require('fizzy-ui-utils'), require('./flickity'));
  } else {
    factory(window.EvEmitter, window.fizzyUIUtils, window.Flickity);
  }
}(window, function factory(EvEmitter, utils, Flickity) {
  'use strict';
  var hiddenProperty,
      visibilityEvent;
  if ('hidden' in document) {
    hiddenProperty = 'hidden';
    visibilityEvent = 'visibilitychange';
  } else if ('webkitHidden' in document) {
    hiddenProperty = 'webkitHidden';
    visibilityEvent = 'webkitvisibilitychange';
  }
  function Player(parent) {
    this.parent = parent;
    this.state = 'stopped';
    if (visibilityEvent) {
      this.onVisibilityChange = function() {
        this.visibilityChange();
      }.bind(this);
      this.onVisibilityPlay = function() {
        this.visibilityPlay();
      }.bind(this);
    }
  }
  Player.prototype = Object.create(EvEmitter.prototype);
  Player.prototype.play = function() {
    if (this.state == 'playing') {
      return;
    }
    var isPageHidden = document[hiddenProperty];
    if (visibilityEvent && isPageHidden) {
      document.addEventListener(visibilityEvent, this.onVisibilityPlay);
      return;
    }
    this.state = 'playing';
    if (visibilityEvent) {
      document.addEventListener(visibilityEvent, this.onVisibilityChange);
    }
    this.tick();
  };
  Player.prototype.tick = function() {
    if (this.state != 'playing') {
      return;
    }
    var time = this.parent.options.autoPlay;
    time = typeof time == 'number' ? time : 3000;
    var _this = this;
    this.clear();
    this.timeout = setTimeout(function() {
      _this.parent.next(true);
      _this.tick();
    }, time);
  };
  Player.prototype.stop = function() {
    this.state = 'stopped';
    this.clear();
    if (visibilityEvent) {
      document.removeEventListener(visibilityEvent, this.onVisibilityChange);
    }
  };
  Player.prototype.clear = function() {
    clearTimeout(this.timeout);
  };
  Player.prototype.pause = function() {
    if (this.state == 'playing') {
      this.state = 'paused';
      this.clear();
    }
  };
  Player.prototype.unpause = function() {
    if (this.state == 'paused') {
      this.play();
    }
  };
  Player.prototype.visibilityChange = function() {
    var isPageHidden = document[hiddenProperty];
    this[isPageHidden ? 'pause' : 'unpause']();
  };
  Player.prototype.visibilityPlay = function() {
    this.play();
    document.removeEventListener(visibilityEvent, this.onVisibilityPlay);
  };
  utils.extend(Flickity.defaults, {pauseAutoPlayOnHover: true});
  Flickity.createMethods.push('_createPlayer');
  var proto = Flickity.prototype;
  proto._createPlayer = function() {
    this.player = new Player(this);
    this.on('activate', this.activatePlayer);
    this.on('uiChange', this.stopPlayer);
    this.on('pointerDown', this.stopPlayer);
    this.on('deactivate', this.deactivatePlayer);
  };
  proto.activatePlayer = function() {
    if (!this.options.autoPlay) {
      return;
    }
    this.player.play();
    this.element.addEventListener('mouseenter', this);
  };
  proto.playPlayer = function() {
    this.player.play();
  };
  proto.stopPlayer = function() {
    this.player.stop();
  };
  proto.pausePlayer = function() {
    this.player.pause();
  };
  proto.unpausePlayer = function() {
    this.player.unpause();
  };
  proto.deactivatePlayer = function() {
    this.player.stop();
    this.element.removeEventListener('mouseenter', this);
  };
  proto.onmouseenter = function() {
    if (!this.options.pauseAutoPlayOnHover) {
      return;
    }
    this.player.pause();
    this.element.addEventListener('mouseleave', this);
  };
  proto.onmouseleave = function() {
    this.player.unpause();
    this.element.removeEventListener('mouseleave', this);
  };
  Flickity.Player = Player;
  return Flickity;
}));

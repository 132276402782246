import 'lazysizes';
import 'a11y-toggle';
import 'plugins/externalLinks';
import 'plugins/shareLinks';
import 'modules/accordion';
import 'modules/careers';
import 'modules/connectedToggles';
import 'modules/homepageCarousel';

// Add "js" class to the html element
document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

// Add focus class to forms when any of their children are focused
Array.from(document.forms).forEach((form) => {
    form.addEventListener('focusin', () => {
        form.classList.add('focus');
    });
    form.addEventListener('focusout', () => {
        form.classList.remove('focus');
    });
});

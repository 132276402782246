/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['./flickity', 'unidragger/unidragger', 'fizzy-ui-utils/utils'], function(Flickity, Unidragger, utils) {
      return factory(window, Flickity, Unidragger, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(window, require('./flickity'), require('unidragger'), require('fizzy-ui-utils'));
  } else {
    window.Flickity = factory(window, window.Flickity, window.Unidragger, window.fizzyUIUtils);
  }
}(window, function factory(window, Flickity, Unidragger, utils) {
  'use strict';
  utils.extend(Flickity.defaults, {
    draggable: true,
    dragThreshold: 3
  });
  Flickity.createMethods.push('_createDrag');
  var proto = Flickity.prototype;
  utils.extend(proto, Unidragger.prototype);
  var isTouch = 'createTouch' in document;
  var isTouchmoveScrollCanceled = false;
  proto._createDrag = function() {
    this.on('activate', this.bindDrag);
    this.on('uiChange', this._uiChangeDrag);
    this.on('childUIPointerDown', this._childUIPointerDownDrag);
    this.on('deactivate', this.unbindDrag);
    if (isTouch && !isTouchmoveScrollCanceled) {
      window.addEventListener('touchmove', function() {});
      isTouchmoveScrollCanceled = true;
    }
  };
  proto.bindDrag = function() {
    if (!this.options.draggable || this.isDragBound) {
      return;
    }
    this.element.classList.add('is-draggable');
    this.handles = [this.viewport];
    this.bindHandles();
    this.isDragBound = true;
  };
  proto.unbindDrag = function() {
    if (!this.isDragBound) {
      return;
    }
    this.element.classList.remove('is-draggable');
    this.unbindHandles();
    delete this.isDragBound;
  };
  proto._uiChangeDrag = function() {
    delete this.isFreeScrolling;
  };
  proto._childUIPointerDownDrag = function(event) {
    event.preventDefault();
    this.pointerDownFocus(event);
  };
  var cursorNodes = {
    TEXTAREA: true,
    INPUT: true,
    OPTION: true
  };
  var clickTypes = {
    radio: true,
    checkbox: true,
    button: true,
    submit: true,
    image: true,
    file: true
  };
  proto.pointerDown = function(event, pointer) {
    var isCursorInput = cursorNodes[event.target.nodeName] && !clickTypes[event.target.type];
    if (isCursorInput) {
      this.isPointerDown = false;
      delete this.pointerIdentifier;
      return;
    }
    this._dragPointerDown(event, pointer);
    var focused = document.activeElement;
    if (focused && focused.blur && focused != this.element && focused != document.body) {
      focused.blur();
    }
    this.pointerDownFocus(event);
    this.dragX = this.x;
    this.viewport.classList.add('is-pointer-down');
    this._bindPostStartEvents(event);
    this.pointerDownScroll = getScrollPosition();
    window.addEventListener('scroll', this);
    this.dispatchEvent('pointerDown', event, [pointer]);
  };
  var touchStartEvents = {
    touchstart: true,
    MSPointerDown: true
  };
  var focusNodes = {
    INPUT: true,
    SELECT: true
  };
  proto.pointerDownFocus = function(event) {
    if (!this.options.accessibility || touchStartEvents[event.type] || focusNodes[event.target.nodeName]) {
      return;
    }
    var prevScrollY = window.pageYOffset;
    this.element.focus();
    if (window.pageYOffset != prevScrollY) {
      window.scrollTo(window.pageXOffset, prevScrollY);
    }
  };
  proto.canPreventDefaultOnPointerDown = function(event) {
    var isTouchstart = event.type == 'touchstart';
    var targetNodeName = event.target.nodeName;
    return !isTouchstart && targetNodeName != 'SELECT';
  };
  proto.hasDragStarted = function(moveVector) {
    return Math.abs(moveVector.x) > this.options.dragThreshold;
  };
  proto.pointerUp = function(event, pointer) {
    delete this.isTouchScrolling;
    this.viewport.classList.remove('is-pointer-down');
    this.dispatchEvent('pointerUp', event, [pointer]);
    this._dragPointerUp(event, pointer);
  };
  proto.pointerDone = function() {
    window.removeEventListener('scroll', this);
    delete this.pointerDownScroll;
  };
  proto.dragStart = function(event, pointer) {
    this.dragStartPosition = this.x;
    this.startAnimation();
    window.removeEventListener('scroll', this);
    this.dispatchEvent('dragStart', event, [pointer]);
  };
  proto.pointerMove = function(event, pointer) {
    var moveVector = this._dragPointerMove(event, pointer);
    this.dispatchEvent('pointerMove', event, [pointer, moveVector]);
    this._dragMove(event, pointer, moveVector);
  };
  proto.dragMove = function(event, pointer, moveVector) {
    event.preventDefault();
    this.previousDragX = this.dragX;
    var direction = this.options.rightToLeft ? -1 : 1;
    var dragX = this.dragStartPosition + moveVector.x * direction;
    if (!this.options.wrapAround && this.slides.length) {
      var originBound = Math.max(-this.slides[0].target, this.dragStartPosition);
      dragX = dragX > originBound ? (dragX + originBound) * 0.5 : dragX;
      var endBound = Math.min(-this.getLastSlide().target, this.dragStartPosition);
      dragX = dragX < endBound ? (dragX + endBound) * 0.5 : dragX;
    }
    this.dragX = dragX;
    this.dragMoveTime = new Date();
    this.dispatchEvent('dragMove', event, [pointer, moveVector]);
  };
  proto.dragEnd = function(event, pointer) {
    if (this.options.freeScroll) {
      this.isFreeScrolling = true;
    }
    var index = this.dragEndRestingSelect();
    if (this.options.freeScroll && !this.options.wrapAround) {
      var restingX = this.getRestingPosition();
      this.isFreeScrolling = -restingX > this.slides[0].target && -restingX < this.getLastSlide().target;
    } else if (!this.options.freeScroll && index == this.selectedIndex) {
      index += this.dragEndBoostSelect();
    }
    delete this.previousDragX;
    this.isDragSelect = this.options.wrapAround;
    this.select(index);
    delete this.isDragSelect;
    this.dispatchEvent('dragEnd', event, [pointer]);
  };
  proto.dragEndRestingSelect = function() {
    var restingX = this.getRestingPosition();
    var distance = Math.abs(this.getSlideDistance(-restingX, this.selectedIndex));
    var positiveResting = this._getClosestResting(restingX, distance, 1);
    var negativeResting = this._getClosestResting(restingX, distance, -1);
    var index = positiveResting.distance < negativeResting.distance ? positiveResting.index : negativeResting.index;
    return index;
  };
  proto._getClosestResting = function(restingX, distance, increment) {
    var index = this.selectedIndex;
    var minDistance = Infinity;
    var condition = this.options.contain && !this.options.wrapAround ? function(d, md) {
      return d <= md;
    } : function(d, md) {
      return d < md;
    };
    while (condition(distance, minDistance)) {
      index += increment;
      minDistance = distance;
      distance = this.getSlideDistance(-restingX, index);
      if (distance === null) {
        break;
      }
      distance = Math.abs(distance);
    }
    return {
      distance: minDistance,
      index: index - increment
    };
  };
  proto.getSlideDistance = function(x, index) {
    var len = this.slides.length;
    var isWrapAround = this.options.wrapAround && len > 1;
    var slideIndex = isWrapAround ? utils.modulo(index, len) : index;
    var slide = this.slides[slideIndex];
    if (!slide) {
      return null;
    }
    var wrap = isWrapAround ? this.slideableWidth * Math.floor(index / len) : 0;
    return x - (slide.target + wrap);
  };
  proto.dragEndBoostSelect = function() {
    if (this.previousDragX === undefined || !this.dragMoveTime || new Date() - this.dragMoveTime > 100) {
      return 0;
    }
    var distance = this.getSlideDistance(-this.dragX, this.selectedIndex);
    var delta = this.previousDragX - this.dragX;
    if (distance > 0 && delta > 0) {
      return 1;
    } else if (distance < 0 && delta < 0) {
      return -1;
    }
    return 0;
  };
  proto.staticClick = function(event, pointer) {
    var clickedCell = this.getParentCell(event.target);
    var cellElem = clickedCell && clickedCell.element;
    var cellIndex = clickedCell && this.cells.indexOf(clickedCell);
    this.dispatchEvent('staticClick', event, [pointer, cellElem, cellIndex]);
  };
  proto.onscroll = function() {
    var scroll = getScrollPosition();
    var scrollMoveX = this.pointerDownScroll.x - scroll.x;
    var scrollMoveY = this.pointerDownScroll.y - scroll.y;
    if (Math.abs(scrollMoveX) > 3 || Math.abs(scrollMoveY) > 3) {
      this._pointerDone();
    }
  };
  function getScrollPosition() {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset
    };
  }
  return Flickity;
}));

/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['./flickity', 'fizzy-ui-utils/utils'], function(Flickity, utils) {
      return factory(window, Flickity, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(window, require('./flickity'), require('fizzy-ui-utils'));
  } else {
    factory(window, window.Flickity, window.fizzyUIUtils);
  }
}(window, function factory(window, Flickity, utils) {
  'use strict';
  function getCellsFragment(cells) {
    var fragment = document.createDocumentFragment();
    cells.forEach(function(cell) {
      fragment.appendChild(cell.element);
    });
    return fragment;
  }
  var proto = Flickity.prototype;
  proto.insert = function(elems, index) {
    var cells = this._makeCells(elems);
    if (!cells || !cells.length) {
      return;
    }
    var len = this.cells.length;
    index = index === undefined ? len : index;
    var fragment = getCellsFragment(cells);
    var isAppend = index == len;
    if (isAppend) {
      this.slider.appendChild(fragment);
    } else {
      var insertCellElement = this.cells[index].element;
      this.slider.insertBefore(fragment, insertCellElement);
    }
    if (index === 0) {
      this.cells = cells.concat(this.cells);
    } else if (isAppend) {
      this.cells = this.cells.concat(cells);
    } else {
      var endCells = this.cells.splice(index, len - index);
      this.cells = this.cells.concat(cells).concat(endCells);
    }
    this._sizeCells(cells);
    var selectedIndexDelta = index > this.selectedIndex ? 0 : cells.length;
    this._cellAddedRemoved(index, selectedIndexDelta);
  };
  proto.append = function(elems) {
    this.insert(elems, this.cells.length);
  };
  proto.prepend = function(elems) {
    this.insert(elems, 0);
  };
  proto.remove = function(elems) {
    var cells = this.getCells(elems);
    var selectedIndexDelta = 0;
    var len = cells.length;
    var i,
        cell;
    for (i = 0; i < len; i++) {
      cell = cells[i];
      var wasBefore = this.cells.indexOf(cell) < this.selectedIndex;
      selectedIndexDelta -= wasBefore ? 1 : 0;
    }
    for (i = 0; i < len; i++) {
      cell = cells[i];
      cell.remove();
      utils.removeFrom(this.cells, cell);
    }
    if (cells.length) {
      this._cellAddedRemoved(0, selectedIndexDelta);
    }
  };
  proto._cellAddedRemoved = function(changedCellIndex, selectedIndexDelta) {
    selectedIndexDelta = selectedIndexDelta || 0;
    this.selectedIndex += selectedIndexDelta;
    this.selectedIndex = Math.max(0, Math.min(this.slides.length - 1, this.selectedIndex));
    this.cellChange(changedCellIndex, true);
    this.emitEvent('cellAddedRemoved', [changedCellIndex, selectedIndexDelta]);
  };
  proto.cellSizeChange = function(elem) {
    var cell = this.getCell(elem);
    if (!cell) {
      return;
    }
    cell.getSize();
    var index = this.cells.indexOf(cell);
    this.cellChange(index);
  };
  proto.cellChange = function(changedCellIndex, isPositioningSlider) {
    var prevSlideableWidth = this.slideableWidth;
    this._positionCells(changedCellIndex);
    this._getWrapShiftCells();
    this.setGallerySize();
    this.emitEvent('cellChange', [changedCellIndex]);
    if (this.options.freeScroll) {
      var deltaX = prevSlideableWidth - this.slideableWidth;
      this.x += deltaX * this.cellAlign;
      this.positionSlider();
    } else {
      if (isPositioningSlider) {
        this.positionSliderAtSelected();
      }
      this.select(this.selectedIndex);
    }
  };
  return Flickity;
}));

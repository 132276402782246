/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['./flickity', 'fizzy-ui-utils/utils'], function(Flickity, utils) {
      return factory(window, Flickity, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(window, require('./flickity'), require('fizzy-ui-utils'));
  } else {
    factory(window, window.Flickity, window.fizzyUIUtils);
  }
}(window, function factory(window, Flickity, utils) {
  'use strict';
  Flickity.createMethods.push('_createLazyload');
  var proto = Flickity.prototype;
  proto._createLazyload = function() {
    this.on('select', this.lazyLoad);
  };
  proto.lazyLoad = function() {
    var lazyLoad = this.options.lazyLoad;
    if (!lazyLoad) {
      return;
    }
    var adjCount = typeof lazyLoad == 'number' ? lazyLoad : 0;
    var cellElems = this.getAdjacentCellElements(adjCount);
    var lazyImages = [];
    cellElems.forEach(function(cellElem) {
      var lazyCellImages = getCellLazyImages(cellElem);
      lazyImages = lazyImages.concat(lazyCellImages);
    });
    lazyImages.forEach(function(img) {
      new LazyLoader(img, this);
    }, this);
  };
  function getCellLazyImages(cellElem) {
    if (cellElem.nodeName == 'IMG' && cellElem.getAttribute('data-flickity-lazyload')) {
      return [cellElem];
    }
    var imgs = cellElem.querySelectorAll('img[data-flickity-lazyload]');
    return utils.makeArray(imgs);
  }
  function LazyLoader(img, flickity) {
    this.img = img;
    this.flickity = flickity;
    this.load();
  }
  LazyLoader.prototype.handleEvent = utils.handleEvent;
  LazyLoader.prototype.load = function() {
    this.img.addEventListener('load', this);
    this.img.addEventListener('error', this);
    this.img.src = this.img.getAttribute('data-flickity-lazyload');
    this.img.removeAttribute('data-flickity-lazyload');
  };
  LazyLoader.prototype.onload = function(event) {
    this.complete(event, 'flickity-lazyloaded');
  };
  LazyLoader.prototype.onerror = function(event) {
    this.complete(event, 'flickity-lazyerror');
  };
  LazyLoader.prototype.complete = function(event, className) {
    this.img.removeEventListener('load', this);
    this.img.removeEventListener('error', this);
    var cell = this.flickity.getParentCell(this.img);
    var cellElem = cell && cell.element;
    this.flickity.cellSizeChange(cellElem);
    this.img.classList.add(className);
    this.flickity.dispatchEvent('lazyLoad', event, cellElem);
  };
  Flickity.LazyLoader = LazyLoader;
  return Flickity;
}));

/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['./flickity', 'tap-listener/tap-listener', 'fizzy-ui-utils/utils'], function(Flickity, TapListener, utils) {
      return factory(window, Flickity, TapListener, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(window, require('./flickity'), require('tap-listener'), require('fizzy-ui-utils'));
  } else {
    factory(window, window.Flickity, window.TapListener, window.fizzyUIUtils);
  }
}(window, function factory(window, Flickity, TapListener, utils) {
  'use strict';
  var svgURI = 'http://www.w3.org/2000/svg';
  function PrevNextButton(direction, parent) {
    this.direction = direction;
    this.parent = parent;
    this._create();
  }
  PrevNextButton.prototype = new TapListener();
  PrevNextButton.prototype._create = function() {
    this.isEnabled = true;
    this.isPrevious = this.direction == -1;
    var leftDirection = this.parent.options.rightToLeft ? 1 : -1;
    this.isLeft = this.direction == leftDirection;
    var element = this.element = document.createElement('button');
    element.className = 'flickity-prev-next-button';
    element.className += this.isPrevious ? ' previous' : ' next';
    element.setAttribute('type', 'button');
    this.disable();
    element.setAttribute('aria-label', this.isPrevious ? 'previous' : 'next');
    var svg = this.createSVG();
    element.appendChild(svg);
    this.on('tap', this.onTap);
    this.parent.on('select', this.update.bind(this));
    this.on('pointerDown', this.parent.childUIPointerDown.bind(this.parent));
  };
  PrevNextButton.prototype.activate = function() {
    this.bindTap(this.element);
    this.element.addEventListener('click', this);
    this.parent.element.appendChild(this.element);
  };
  PrevNextButton.prototype.deactivate = function() {
    this.parent.element.removeChild(this.element);
    TapListener.prototype.destroy.call(this);
    this.element.removeEventListener('click', this);
  };
  PrevNextButton.prototype.createSVG = function() {
    var svg = document.createElementNS(svgURI, 'svg');
    svg.setAttribute('viewBox', '0 0 100 100');
    var path = document.createElementNS(svgURI, 'path');
    var pathMovements = getArrowMovements(this.parent.options.arrowShape);
    path.setAttribute('d', pathMovements);
    path.setAttribute('class', 'arrow');
    if (!this.isLeft) {
      path.setAttribute('transform', 'translate(100, 100) rotate(180) ');
    }
    svg.appendChild(path);
    return svg;
  };
  function getArrowMovements(shape) {
    if (typeof shape == 'string') {
      return shape;
    }
    return 'M ' + shape.x0 + ',50' + ' L ' + shape.x1 + ',' + (shape.y1 + 50) + ' L ' + shape.x2 + ',' + (shape.y2 + 50) + ' L ' + shape.x3 + ',50 ' + ' L ' + shape.x2 + ',' + (50 - shape.y2) + ' L ' + shape.x1 + ',' + (50 - shape.y1) + ' Z';
  }
  PrevNextButton.prototype.onTap = function() {
    if (!this.isEnabled) {
      return;
    }
    this.parent.uiChange();
    var method = this.isPrevious ? 'previous' : 'next';
    this.parent[method]();
  };
  PrevNextButton.prototype.handleEvent = utils.handleEvent;
  PrevNextButton.prototype.onclick = function() {
    var focused = document.activeElement;
    if (focused && focused == this.element) {
      this.onTap();
    }
  };
  PrevNextButton.prototype.enable = function() {
    if (this.isEnabled) {
      return;
    }
    this.element.disabled = false;
    this.isEnabled = true;
  };
  PrevNextButton.prototype.disable = function() {
    if (!this.isEnabled) {
      return;
    }
    this.element.disabled = true;
    this.isEnabled = false;
  };
  PrevNextButton.prototype.update = function() {
    var slides = this.parent.slides;
    if (this.parent.options.wrapAround && slides.length > 1) {
      this.enable();
      return;
    }
    var lastIndex = slides.length ? slides.length - 1 : 0;
    var boundIndex = this.isPrevious ? 0 : lastIndex;
    var method = this.parent.selectedIndex == boundIndex ? 'disable' : 'enable';
    this[method]();
  };
  PrevNextButton.prototype.destroy = function() {
    this.deactivate();
  };
  utils.extend(Flickity.defaults, {
    prevNextButtons: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30
    }
  });
  Flickity.createMethods.push('_createPrevNextButtons');
  var proto = Flickity.prototype;
  proto._createPrevNextButtons = function() {
    if (!this.options.prevNextButtons) {
      return;
    }
    this.prevButton = new PrevNextButton(-1, this);
    this.nextButton = new PrevNextButton(1, this);
    this.on('activate', this.activatePrevNextButtons);
  };
  proto.activatePrevNextButtons = function() {
    this.prevButton.activate();
    this.nextButton.activate();
    this.on('deactivate', this.deactivatePrevNextButtons);
  };
  proto.deactivatePrevNextButtons = function() {
    this.prevButton.deactivate();
    this.nextButton.deactivate();
    this.off('deactivate', this.deactivatePrevNextButtons);
  };
  Flickity.PrevNextButton = PrevNextButton;
  return Flickity;
}));

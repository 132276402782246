/**
 * Implement Flickity for the main carousel on the homepage
 *
 * (Only used on larger screens)
 */

import Flickity from 'flickity';

export default ((document) => {
    const carousel = document.querySelector('.js-carousel');
    const thumbs = document.querySelector('.js-slider-controls');

    if (carousel) {
        /**
         * Initialize Flickity
         */
        const flickity = new Flickity(carousel, {
            cellSelector: '.js-slide',
            setGallerySize: false,
            autoPlay: 7000,
            wrapAround: true,
            watchCSS: true,
            selectedAttraction: 0.02,
            friction: 0.3,
            pageDots: false,
            arrowShape: {
                x0: 10,
                x1: 60, y1: 50,
                x2: 65, y2: 45,
                x3: 20,
            },
        });

        /**
         * Thumbnail navigation
         */

        // update buttons on select
        flickity.on('select', () => {
            const previousActive = thumbs.querySelector('.is-active');
            const newActive = thumbs.children[flickity.selectedIndex];
            if (previousActive) {
                previousActive.classList.remove('is-active');
            }
            newActive.classList.add('is-active');

            // Update the text color class
            const color = flickity.selectedElement.getAttribute('data-color');
            carousel.classList.remove('color-gray', 'color-black', 'color-white');
            carousel.classList.add(`color-${color}`);
        });

        // cell select
        thumbs.addEventListener('click', (event) => {
            if (event.target.nodeName !== 'DIV') {
                return;
            }
            const index = Array.prototype.indexOf.call(thumbs.children, event.target);
            flickity.select(index);
            flickity.stopPlayer();
        });

        // Pause player when thumbs have focus
        thumbs.addEventListener('focusin', (event) => {
            flickity.pausePlayer();
        });
        thumbs.addEventListener('focusout', (event) => {
            flickity.unpausePlayer();
        });

        // Pass arrow key events to the carousel
        thumbs.addEventListener('keyup', (event) => {
            if (event.keyCode == 37) {
                flickity.previous(true);
            } else if (event.keyCode == 39) {
                flickity.next(true);
            }
        });
    }
})(window.document);

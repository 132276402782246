/* */ 
"format cjs";
(function(window, factory) {
  if (typeof define == 'function' && define.amd) {
    define(['./flickity', 'tap-listener/tap-listener', 'fizzy-ui-utils/utils'], function(Flickity, TapListener, utils) {
      return factory(window, Flickity, TapListener, utils);
    });
  } else if (typeof module == 'object' && module.exports) {
    module.exports = factory(window, require('./flickity'), require('tap-listener'), require('fizzy-ui-utils'));
  } else {
    factory(window, window.Flickity, window.TapListener, window.fizzyUIUtils);
  }
}(window, function factory(window, Flickity, TapListener, utils) {
  'use strict';
  function PageDots(parent) {
    this.parent = parent;
    this._create();
  }
  PageDots.prototype = new TapListener();
  PageDots.prototype._create = function() {
    this.holder = document.createElement('ol');
    this.holder.className = 'flickity-page-dots';
    this.dots = [];
    this.on('tap', this.onTap);
    this.on('pointerDown', this.parent.childUIPointerDown.bind(this.parent));
  };
  PageDots.prototype.activate = function() {
    this.setDots();
    this.bindTap(this.holder);
    this.parent.element.appendChild(this.holder);
  };
  PageDots.prototype.deactivate = function() {
    this.parent.element.removeChild(this.holder);
    TapListener.prototype.destroy.call(this);
  };
  PageDots.prototype.setDots = function() {
    var delta = this.parent.slides.length - this.dots.length;
    if (delta > 0) {
      this.addDots(delta);
    } else if (delta < 0) {
      this.removeDots(-delta);
    }
  };
  PageDots.prototype.addDots = function(count) {
    var fragment = document.createDocumentFragment();
    var newDots = [];
    while (count) {
      var dot = document.createElement('li');
      dot.className = 'dot';
      fragment.appendChild(dot);
      newDots.push(dot);
      count--;
    }
    this.holder.appendChild(fragment);
    this.dots = this.dots.concat(newDots);
  };
  PageDots.prototype.removeDots = function(count) {
    var removeDots = this.dots.splice(this.dots.length - count, count);
    removeDots.forEach(function(dot) {
      this.holder.removeChild(dot);
    }, this);
  };
  PageDots.prototype.updateSelected = function() {
    if (this.selectedDot) {
      this.selectedDot.className = 'dot';
    }
    if (!this.dots.length) {
      return;
    }
    this.selectedDot = this.dots[this.parent.selectedIndex];
    this.selectedDot.className = 'dot is-selected';
  };
  PageDots.prototype.onTap = function(event) {
    var target = event.target;
    if (target.nodeName != 'LI') {
      return;
    }
    this.parent.uiChange();
    var index = this.dots.indexOf(target);
    this.parent.select(index);
  };
  PageDots.prototype.destroy = function() {
    this.deactivate();
  };
  Flickity.PageDots = PageDots;
  utils.extend(Flickity.defaults, {pageDots: true});
  Flickity.createMethods.push('_createPageDots');
  var proto = Flickity.prototype;
  proto._createPageDots = function() {
    if (!this.options.pageDots) {
      return;
    }
    this.pageDots = new PageDots(this);
    this.on('activate', this.activatePageDots);
    this.on('select', this.updateSelectedPageDots);
    this.on('cellChange', this.updatePageDots);
    this.on('resize', this.updatePageDots);
    this.on('deactivate', this.deactivatePageDots);
  };
  proto.activatePageDots = function() {
    this.pageDots.activate();
  };
  proto.updateSelectedPageDots = function() {
    this.pageDots.updateSelected();
  };
  proto.updatePageDots = function() {
    this.pageDots.setDots();
  };
  proto.deactivatePageDots = function() {
    this.pageDots.deactivate();
  };
  Flickity.PageDots = PageDots;
  return Flickity;
}));

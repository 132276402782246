/**
 * CSS transition-based accordion
 */

export default (document => {
    const inputs = Array.prototype.slice.call(
        document.querySelectorAll(".careers select")
    );

    function closeAll() {
        var closeable = document.querySelectorAll(".popup");

        closeable.forEach(function(i){
            i.setAttribute("aria-hidden", true);
        })
    }
    document.querySelectorAll(".close");
    if (inputs.length == 2) {
        inputs[0].addEventListener("change", function(e) {
            closeAll();
            document
                .querySelectorAll(".job-" + e.target.value)[0]
                .setAttribute("aria-hidden", false);
        });
        inputs[1].addEventListener("change", function(e) {
            closeAll();
            document
                .querySelectorAll(".job-" + e.target.value)[0]
                .setAttribute("aria-hidden", false);
        });
    }

    const anchors = Array.prototype.slice.call(
        document.querySelectorAll(".close")
    );
    anchors.forEach(function(elem){
        elem.onclick =  closeAll;
    })
})(window.document);

/**
 * Open sharing links in a new window
 */

export default ((document) => {
    const shareLinks = document.querySelectorAll('.js-share-link');

    function openLink(event) {
        const left = (screen.width / 2) - 290;
        const top = (screen.height / 2) - 150;
        window.open(this.href, 'share', `width=580,height=300,top=${top},left=${left}`);
        event.preventDefault();
    }

    Array.from(shareLinks).forEach((link) => {
        link.addEventListener('click', openLink);
    });
})(document);
